import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledTextArea,
  ErrorMessage,
  StyledSelectWrapper,
  StyledSelect,
  StyledOption
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const FhtjConsultationForm = () => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)

  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }

    if (!values.telephone) {
      errors.telephone = "Required"
    }
    if (!values.dob) {
      errors.dob = "Required"
    }
    if (!values.gender) {
      errors.gender = "Required"
    }

    if (!values.occupation) {
      errors.occupation = "Required"
    }
    if (!values.primaryRea) {
      errors.primaryRea = "Required"
    }

    if (!values.symptomsSt) {
      errors.symptomsSt = "Required"
    }
    if (!values.medication) {
      errors.medication = "Required"
    }
    if (!values.hadEnergyHealingBefore) {
      errors.hadEnergyHealingBefore = "Required"
    }

    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }


    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      dob: "",
      gender: "",
      occupation: "",
      primaryRea: "",
      symptomsSt: "",
      medication: "",
      hadEnergyHealingBefore: "",
      painLevel: "",
      howHear: "FMTJ",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        telephone,
        dob,
        gender,
        occupation,
        primaryRea,
        symptomsSt,
        medication,
        hadEnergyHealingBefore,
        painLevel,
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      addToMailchimp(values.email, {
        firstName: values.firstName,
        lastName: values.lastName,
        telephone: values.telephone,
        dob: values.dob.replace('/', '-'),
        gender: values.gender,
        occupation: values.occupation,
        primaryRea: values.primaryRea,
        symptomsSt: values.symptomsSt,
        medication: values.medication,
        hadEnergyHealingBefore: values.hadEnergyHealingBefore,
        painLevel: values.painLevel,
        howHEar: 'FMTJ',
        'group[380914][128]': '1'
      }
      )
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      bodyFormData.set("telephone", telephone)
      bodyFormData.set("dob", dob)
      bodyFormData.set("gender", gender)
      bodyFormData.set("occupation", occupation)
      bodyFormData.set("primaryRea", primaryRea)
      bodyFormData.set("symptomsSt", symptomsSt)
      bodyFormData.set("medication", medication)
      bodyFormData.set("hadEnergyHealingBefore", hadEnergyHealingBefore)
      bodyFormData.set("painLevel", painLevel)
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/10475/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/bookings/fhtj/thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFieldset>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="firstName">
            <StyledLabelHeader
              required
              isEmpty={errors.firstName && touched.firstName}
            >
              First Name
            </StyledLabelHeader>
            <StyledInput
              id="firstName"
              name="firstName"
              type="text"
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.firstName && touched.firstName ? (
            <span className="error-message">{errors.firstName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="lastName">
            <StyledLabelHeader
              required
              isEmpty={errors.lastName && touched.lastName}
            >
              Last Name
            </StyledLabelHeader>
            <StyledInput
              id="lastName"
              name="lastName"
              type="text"
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.lastName && touched.lastName ? (
            <span className="error-message">{errors.lastName}</span>
          ) : null}
        </StyledFormElementWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="email">
            <StyledLabelHeader
              required
              isEmpty={errors.email && touched.email}
            >
              Email Address
            </StyledLabelHeader>
            <StyledInput
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur("email")}
              required
            />
          </StyledLabel>
          {errors.email && touched.email ? (
            <span className="error-message">{errors.email}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="telephone">
            <StyledLabelHeader
              required
              isEmpty={errors.telephone && touched.telephone}
            >
              Phone Number <small>(Please inlcude county code)</small></StyledLabelHeader>

            <StyledInput
              id="telephone"
              name="telephone"
              type="tel"
              onChange={handleChange}
              value={values.telephone}
              placeholder="+44 (0)7400 123456"
              onBlur={handleBlur("telephone")}
              required
            />
          </StyledLabel>
          {errors.telephone && touched.telephone ? (
            <span className="error-message">{errors.telephone}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="dob">
            <StyledLabelHeader
              required
              isEmpty={errors.dob && touched.dob}
            >
              Date of birth</StyledLabelHeader>

            <StyledInput
              id="dob"
              name="dob"
              type="date"
              onChange={handleChange}
              value={values.dob}
              onBlur={handleBlur("dob")}
              required
            />
          </StyledLabel>
          {errors.dob && touched.dob ? (
            <span className="error-message">{errors.dob}</span>
          ) : null}
        </StyledFormElementWrapper>


        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            isEmpty={errors.gender && touched.gender}
          >Gender</StyledLabelHeader>
          <ul role="group" aria-labelledby="my-radio-group">
            <li>
              <StyledLabel htmlFor="female">
                <StyledInput
                  type="radio"
                  id="female"
                  name="gender"
                  value="Female"
                  checked={values.gender === "Female"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span>Female</span>
              </StyledLabel>
            </li>
            <li>
              <StyledLabel htmlFor="male">
                <StyledInput
                  type="radio"
                  id="male"
                  name="gender"
                  value="Male"
                  checked={values.gender === "Male"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span>Male</span>
              </StyledLabel>
            </li>
          </ul>
          {errors.gender && touched.gender ? (
            <span className="error-message">{errors.gender}</span>
          ) : null}
        </StyledFormElementWrapper>


        <StyledFormElementWrapper>
          <StyledLabel htmlFor="occupation">
            <StyledLabelHeader
              required
              isEmpty={errors.occupation && touched.occupation}
            >
              Occupation
            </StyledLabelHeader>
            <StyledInput
              id="occupation"
              name="occupation"
              type="text"
              onChange={handleChange}
              value={values.occupation}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.occupation && touched.occupation ? (
            <span className="error-message">{errors.occupation}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="primaryRea">
            <StyledLabelHeader
              required
              isEmpty={errors.primaryRea && touched.primaryRea}
            >
              Primary reason for this healing session?</StyledLabelHeader>
            <StyledTextArea
              id="primaryRea"
              name="primaryRea"
              type="text"
              onChange={handleChange}
              value={values.primaryRea}
              onBlur={handleBlur}

              required
            />
          </StyledLabel>
          {errors.primaryRea && touched.primaryRea ? (
            <span className="error-message">{errors.primaryRea}</span>
          ) : null}
        </StyledFormElementWrapper>


        <StyledFormElementWrapper>
          <StyledLabel htmlFor="symptomsSt">
            <StyledLabelHeader
              required
              isEmpty={errors.symptomsSt && touched.symptomsSt}
            >
              When did your symptoms start:</StyledLabelHeader>
            <StyledTextArea
              id="symptomsSt"
              name="symptomsSt"
              type="text"
              onChange={handleChange}
              value={values.symptomsSt}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.symptomsSt && touched.symptomsSt ? (
            <span className="error-message">{errors.symptomsSt}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
          <StyledLabel htmlFor="medication">
            <StyledLabelHeader
              required
              isEmpty={errors.medication && touched.medication}
            >
              Are you on any medication:</StyledLabelHeader>
            <StyledTextArea
              id="medication"
              name="medication"
              type="text"
              onChange={handleChange}
              value={values.medication}
              onBlur={handleBlur}
              required
            />
          </StyledLabel>
          {errors.medication && touched.medication ? (
            <span className="error-message">{errors.medication}</span>
          ) : null}
        </StyledFormElementWrapper>

        <StyledSelectWrapper>
          <StyledFormElementWrapper>
            <StyledLabel htmlFor="painLevel">
              <StyledLabelHeader>Please rate your pain level from 1 to 10?</StyledLabelHeader>
              <StyledSelect
                defaultValue="default"
                id="painLevel"
                name="painLevel"
                onChange={handleChange}
                onBlur={handleBlur}
                required
              >
                <StyledOption value="default" disabled hidden>Please select?</StyledOption>
                <StyledOption value="1">1 - Low amount of pain</StyledOption>
                <StyledOption value="2">2</StyledOption>
                <StyledOption value="3">3</StyledOption>
                <StyledOption value="4">4</StyledOption>
                <StyledOption value="5">5</StyledOption>
                <StyledOption value="6">6</StyledOption>
                <StyledOption value="7">7</StyledOption>
                <StyledOption value="8">8</StyledOption>
                <StyledOption value="9">9</StyledOption>
                <StyledOption value="10">10 - High amount of pain</StyledOption>
              </StyledSelect>
            </StyledLabel>
          </StyledFormElementWrapper>
        </StyledSelectWrapper>

        <StyledFormElementWrapper>
          <StyledLabelHeader
            required
            isEmpty={errors.hadEnergyHealingBefore && touched.hadEnergyHealingBefore}
          >Have you had any energy healing before?</StyledLabelHeader>
          <ul role="group" aria-labelledby="my-radio-group">
            <li>
              <StyledLabel htmlFor="yes">
                <StyledInput
                  type="radio"
                  id="yes"
                  name="hadEnergyHealingBefore"
                  value="Yes"
                  checked={values.hadEnergyHealingBefore === "Yes"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span>Yes</span>
              </StyledLabel>
            </li>
            <li>
              <StyledLabel htmlFor="no">
                <StyledInput
                  type="radio"
                  id="no"
                  name="hadEnergyHealingBefore"
                  value="No"
                  checked={values.hadEnergyHealingBefore === "No"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span>No</span>
              </StyledLabel>
            </li>
          </ul>
          {errors.hadEnergyHealingBefore && touched.hadEnergyHealingBefore ? (
            <span className="error-message">{errors.hadEnergyHealingBefore}</span>
          ) : null}
        </StyledFormElementWrapper>



      </StyledFieldset>
      <StyledButton
        type="submit"
        value="Send Message"
        disabled={isSubmitting || !isFormValid}
      >
        Submit
      </StyledButton>
      {messageSent && !isSuccessMessage && (
        <ErrorMessage>
          <p>Something went wrong please try again.</p>
        </ErrorMessage>
      )}
    </StyledForm>
  )
}

export default FhtjConsultationForm
